import React from 'react';

const BlogExpHeader = (props) => {
  return (
    <section className="blog-expanded-header">
      <div
        className="blog-expanded-image"
        // style={{ backgroundImage: `url(${props.source})`, height: 85vh }}
        style={{
          backgroundImage: `linear-gradient(
            rgba(6, 105, 171, 0.6),
            rgba(6, 105, 171, 0.6)
          ),url(${props.source})`,
        }}
      >
        <p className="blog-expanded-title">{props.title}</p>
      </div>
    </section>
  );
};

export default BlogExpHeader;
