import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import BlogExpHeader from '../components/blog-expanded-header';
import ContactBanner from '../components/contact-banner';
import Contact from '../components/contact';

export const query = graphql`
  query($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        author
        date
        authorPic {
          childImageSharp {
            fluid(maxWidth: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`;
const BlogExp = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <BlogExpHeader
        source={require('../images/design-trend.jpg')}
        title={frontmatter.title}
      ></BlogExpHeader>
      <section className="blog-content">
        <div className="row meow">
          <div className="blog-details">
            {frontmatter.authorPic ? (
              <Img
                className="author-img"
                fluid={frontmatter.authorPic.childImageSharp.fluid}
                style={{ borderRadius: 40, width: 80, height: 80 }}
              ></Img>
            ) : (
              <img
                className="author-img"
                src={require('../images/authors/author.png')}
                style={{ borderRadius: 40, width: 80, height: 80 }}
                alt="Author"
              ></img>
            )}

            <p className="author-name">
              {' '}
              {frontmatter.author ? frontmatter.author : 'Author'}
            </p>
            <p className="date">Posted on {frontmatter.date}</p>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          ></div>
        </div>
      </section>
      <section class="contactt">
        <ContactBanner />
        <Contact />
      </section>
    </Layout>
  );
};

export default BlogExp;
